import { enableProdMode, APP_INITIALIZER, PLATFORM_ID, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { PaginatorModule } from 'primeng/paginator';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TooltipModule } from 'primeng/tooltip';
import { ImageCropperModule } from 'ngx-image-cropper';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastModule } from 'primeng/toast';
import { FormsModule } from '@angular/forms';
import { CarouselModule } from 'primeng/carousel';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogModule } from 'primeng/dialog';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations, provideNoopAnimations } from '@angular/platform-browser/animations';
import { SocketService } from './app/services/socket.service';
import { IframePlayerResolver } from './app/components/iframe-player/iframe-player.resolver';
import { LiveGuard } from './app/guards/live.guard';
import { GivingGuard } from './app/guards/giving.guard';
import { NetworkGuard } from './app/guards/network.guard';
import { ChannelGuard } from './app/guards/channel.guard';
import { VodGuard } from './app/guards/vod.guard';
import { JoinGuard } from './app/guards/join.guard';
import { MobileAppGuard } from './app/guards/mobile-app.guard';
import { HomeGuard } from './app/guards/home.guard';
import { EbookGuard } from './app/guards/ebook.guard';
import { AuthGuard } from './app/guards/auth.guard';
import { ProgramsM3uResolver } from './app/components/programs-m3u/programs-m3u.resolver';
import { M3uDetailPageResolver } from './app/components/m3u-detail-page/m3u-detail-page.resolver';
import { HomeResolver } from './app/components/home/home.resolver';
import { EbookResolver } from './app/components/ebook/ebook.resolver';
import { EbookDetailResolver } from './app/components/ebook-detail/ebook-detail.resolver';
import { WatchDailyResolver } from './app/components/watchdaily/watchdaily.resolver';
import { ProgramsResolver } from './app/components/programs/programs.resolver';
import { MessageService } from 'primeng/api';
import { isPlatformBrowser, ɵgetDOM } from '@angular/common';
import { AppInterceptor } from './app/interceptors/interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { routes } from './app/app-route';
import { provideNgxStripe } from 'ngx-stripe';

if (environment.production) {
  enableProdMode();
}

const components = [
  MessageService,
  ProgramsResolver,
  WatchDailyResolver,
  EbookDetailResolver,
  EbookResolver,
  HomeResolver,
  M3uDetailPageResolver,
  ProgramsM3uResolver,
  AuthGuard,
  EbookGuard,
  HomeGuard,
  MobileAppGuard,
  JoinGuard,
  VodGuard,
  ChannelGuard,
  NetworkGuard,
  GivingGuard,
  LiveGuard,
  IframePlayerResolver,
  SocketService,
  provideAnimations(),
  provideHttpClient(withInterceptorsFromDi()),
  provideNoopAnimations(),
  provideRouter(routes),
  provideNgxStripe()
];

function bootstrap(): void {
  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        DialogModule,
        NgbModule,
        CarouselModule,
        FormsModule,
        ToastModule,
        BsDropdownModule,
        ImageCropperModule,
        TooltipModule,
        MatProgressSpinnerModule,
        InputNumberModule,
        DropdownModule,
        PaginatorModule,
      ),
      { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
      {
        provide: APP_INITIALIZER,
        useFactory: (platformId: object) => () => {
          if (isPlatformBrowser(platformId)) {
            const dom = ɵgetDOM();
            const styles = Array.prototype.slice.apply(
              dom.getDefaultDocument().querySelectorAll('style[ng-transition]'),
            );
            styles.forEach((el) => {
              el.removeAttribute('ng-transition');
            });
            dom.getDefaultDocument().addEventListener('PrebootComplete', () => {
              styles.forEach((el) => dom.remove(el));
            });
          }
        },
        deps: [PLATFORM_ID],
        multi: true,
      },
      ...components,
    ],
  }).catch((err) => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
