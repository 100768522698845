import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';

@Injectable()
export class SocketService {
  socket: Socket;
  constructor() {
    this.socket = io('https://socket.nowcast.cc');
    // this.socket = io('https://websocket.nowcast.cc');
  }

  sendData(data: any, type: string): void {
    switch (type) {
      case 'DATA_USAGE':
        this.socket.emit('data_usage', data);
        break;
      case 'VIEWER_COUNT':
        this.socket.emit('viewer', data);
        break;
    }
  }
  onViewerMessage(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('VIEWER_COUNT_MESSAGE', (msg) => {
        observer.next(msg);
      });
    });
  }
  closeConnection(): void {
    this.socket.disconnect();
  }
}
