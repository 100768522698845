import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { AngularFaviconService } from 'angular-favicon';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { HomeService } from 'src/app/services/home/home.service';
import { LoginService } from 'src/app/services/login/login.service';
import { MediaService } from 'src/app/services/media.service';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogModule } from 'primeng/dialog';
import { CarouselModule } from 'primeng/carousel';
import { PaginatorModule } from 'primeng/paginator';
@Component({
  standalone: true,
  imports: [
    NgbCarouselModule,
    CommonModule,
    DialogModule,
    CarouselModule,
    FormsModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
  ],
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userDetail: any;
  userName: any;
  homeLogo = 'assets/images/logo.png';
  headerLinkColor: any;
  logoSize: any;
  theme: any;
  headerLogo: any;
  favIcon: any;
  web: any;
  showMenu = false;
  mainPageName: any;
  vodPageName: any;
  contactName: any;
  appBGColor: any;
  appStoreName: any;
  channelName: any;
  networkName: any;
  givingPageName: any;
  appBtnPrimaryColor: any;
  mainBGBlur: any;
  footerBlur: any;
  blurOpacity: any;
  scrHeight: any;
  signoutModal = false;
  scrWidth: any;
  removeheaderData = false;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  showHideEbook: boolean;
  showHideGiving: boolean;
  ebookPage: string;
  isEbookUrl: boolean;
  isVideoUrl: boolean;
  isAppStore: boolean;
  isChannelPage: boolean;
  isNetworkPage: boolean;
  appStorePage: string;
  isHomePage: boolean;
  isVodPage: boolean;
  organizationUuid: string;
  isLivePage: boolean;
  livePageContent: string;
  constructor(
    public router: Router,
    public messageService: MessageService,
    public loginService: LoginService,
    public homeService: HomeService,
    private elementRef: ElementRef,
    private ngxFavicon: AngularFaviconService,
    private sanitizer: DomSanitizer,
    private _MediaService: MediaService,
  ) {
    this.getScreenSize();
    this.showHideEbook = false;
    this.showHideGiving = false;
    this.ebookPage = '';
    this.isEbookUrl = false;
    this.isVideoUrl = false;
    this.isAppStore = false;
    this.isChannelPage = false;
    this.isNetworkPage = false;
    this.appStorePage = '';
    this.isHomePage = false;
    this.isVodPage = false;
    this.organizationUuid = '';
    this.isLivePage = false;
    this.livePageContent = '';
  }

  ngOnInit(): void {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.loadHeaderContent();
  }

  loadHeaderContent(): void {
    this.userDetail = localStorage.getItem('loggedInUser');
    this.userName = this.userDetail ? JSON.parse(this.userDetail).userDetails.firstName : '';
    this.web = `${window.location.protocol}//${window.location.hostname}/`;
    if (this.router.url.includes('ebook')) {
      this.isEbookUrl = true;
    }
    if (this.router.url.includes('video')) {
      this.isVideoUrl = true;
    }
    this.getSubscribe();
  }
  logOut() {
    this.loginService.logOutUser().subscribe(
      (response: any) => {
        this._MediaService.setVideoPlayerStatus(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Logged out successful.',
        });
        this.signoutModal = false;
        localStorage.clear();
        this.loadHeaderContent();
        this.router.navigate(['/main']);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      },
    );
  }

  getSubscribe() {
    this.homeService.sendPageData$.subscribe((response: any) => {
      console.log(response, 'response');
      if (response.data) {
        this.mainPageName = response.data.pageCustomizationDTO.homePage;
        this.vodPageName = response.data.pageCustomizationDTO.watchDailyPage;
        this.contactName = response.data.pageCustomizationDTO.contactUs;
        this.showHideEbook = response.data.pageCustomizationDTO.showHideEbook;
        this.appStoreName = response.data.pageCustomizationDTO.appStorePage;
        this.channelName = response.data.pageCustomizationDTO.channelName;
        this.networkName = response.data.pageCustomizationDTO.networkName;
        this.givingPageName = response.data.pageCustomizationDTO.givingPage;
        this.showHideGiving = response.data.pageCustomizationDTO.showHideGiving;
        this.ebookPage = response.data.pageCustomizationDTO.ebookPage;
        this.isHomePage = response.data.pageCustomizationDTO.showHideHome;
        this.isVodPage = response.data.pageCustomizationDTO.showHideVOD;
        this.isAppStore = response.data.pageCustomizationDTO.showHideAppstore;
        this.isChannelPage = response.data.pageCustomizationDTO.showHideTvChannel;
        this.isNetworkPage = response.data.pageCustomizationDTO.showHideNetwork;
        this.appStorePage = response.data.pageCustomizationDTO.appStorePage;
        this.organizationUuid = response.data.pageCustomizationDTO.uuid;
        this.isLivePage = response.data.pageCustomizationDTO.isIFrame;
        this.livePageContent = response.data.pageCustomizationDTO.liveStreamTabName.trim();
        // webappSetting -----------------
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.appBGColor;

        this.appBtnPrimaryColor = this.sanitizer.bypassSecurityTrustStyle(
          `${response.data.pageCustomizationDTO.primaryColor}`,
        );

        this.headerLinkColor = response.data.headerDTO.headerLinksColor;
        if (this.scrWidth < 500) {
          this.logoSize = response.data.headerDTO.mobileLogoSize;
        } else if (this.scrWidth > 500 && this.scrWidth < 900) {
          this.logoSize = response.data.headerDTO.tabletLogoSize;
        } else {
          this.logoSize = response.data.headerDTO.webLogoSize;
        }
        this.theme = response.data.pageCustomizationDTO.colorScheme
          ? (response.data.pageCustomizationDTO.colorScheme = 'light')
          : (response.data.pageCustomizationDTO.colorScheme = 'dark');
        this.headerLogo = response.data.headerDTO.logoId
          ? `${appConfig.imageURL}` + response.data.headerDTO.logoId
          : '';

        this.favIcon = response.data.pageCustomizationDTO.faviconId
          ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.faviconId
          : '';
        this.ngxFavicon.setFavicon(this.favIcon);
        if (this.router.url.includes('giving') && this.scrWidth < 500) {
          this.removeheaderData = true;
        }
      }
    });
  }

  openNav() {
    const sidebar = document.getElementById('mySidenav');
    if (this.scrWidth < 650) {
      sidebar ? (sidebar.style.width = '500px') : null;
    } else if (this.scrWidth > 650 && this.scrWidth < 900) {
      sidebar ? (sidebar.style.width = '700px') : null;
    }
    // document.getElementById("mySidenav").style.width = "250px";
  }

  closeNav() {
    const sidebar = document.getElementById('mySidenav');
    sidebar ? (sidebar.style.width = '0') : null;
    // setTimeout(() => {
    //   if (this.router.url.includes('giving') && this.scrWidth < 500) {
    //     this.removeheaderData = true;
    //   } else {
    //     this.removeheaderData = false;
    //   }
    // }, 300);
    // document.getElementById("mySidenav").style.width = "250px";
  }
  navigateForFreeAccount(): void {
    window.open(`${environment.dashboard}signup/${this.organizationUuid}`, '_blank');
  }

  onImageLoad(event: Event) {
    const img = event.target as HTMLImageElement;
    const width = img.width;
    const height = img.height;

    this.homeService.setDimensions(width, height);
  }
}
