import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HomeService } from 'src/app/services/home/home.service';
import { SessionService } from 'src/app/services/shared/session.service';

@Injectable({
  providedIn: 'root',
})
export class EbookDetailResolver {
  isLoggedIn: boolean;
  constructor(
    private _HomeService: HomeService,
    private _SessionService: SessionService,
  ) {
    this.isLoggedIn = false;
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const id = route.params.id;
    this.isLoggedIn = this._SessionService.getSession('loggedInUser') ? true : false;
    if (this.isLoggedIn) {
      return this._HomeService
        .getEbookMediaDataByIdWith(id)
        .toPromise()
        .then((data) => data);
    }
    return this._HomeService
      .getEbookMediaDataByIdWithout(id)
      .toPromise()
      .then((data) => data);
  }
}
