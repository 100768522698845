import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { getWindow } from 'ssr-window';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  constructor(
    public httpclient: HttpClient,
    private _Router: Router,
    private _MessageService: MessageService,
  ) {}

  /**
   * To get data
   * @param url API Endpoint
   */
  getData(url: any) {
    return this.httpclient.get<any>(url).pipe(
      catchError((error) => {
        console.log(url);
        return this.errorHandler(error);
      }),
    );
  }

  /**
   * To save data
   * @param data Data to be send
   * @param url API Endpoint
   */
  postData(url: any, data?: any) {
    return this.httpclient.post<any>(url, data, { observe: 'response' }).pipe(
      catchError((error) => {
        console.log(url);
        return this.errorHandler(error);
      }),
    );
  }

  /**
   * To update data
   * @param data Data to be send
   * @param url API Endpoint
   */
  putData(url: any, data?: any) {
    return this.httpclient.put<any>(url, data, { observe: 'response' }).pipe(
      catchError((error) => {
        console.log(url);
        return this.errorHandler(error);
      }),
    );
  }

  /**
   * To delete data
   * @param url API Endpoint
   */
  deleteData(url: any) {
    return this.httpclient.delete<any>(url, { observe: 'response' }).pipe(
      catchError((error) => {
        console.log(url);
        return this.errorHandler(error);
      }),
    );
  }

  /**
   * Error Handler
   * @param error Error
   */
  errorHandler(error: HttpErrorResponse) {
    console.log(error);
    if (error.status === 401 || error.status === 500) {
      if (error.url && error.url.split('/').reverse()[0] === 'loginUser') {
        getWindow().localStorage.clear();
      } else if (error && error.error && error.error.message.includes('deactivated')) {
        this._Router.navigate(['/site-error']);
      } else {
        getWindow().localStorage.clear();
        this._Router.navigate(['/main']);
      }
    }
    return throwError(error || 'Error');
  }
}
