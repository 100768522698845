import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { MessageService } from 'primeng/api';
import { HomeService } from 'src/app/services/home/home.service';
import { SessionService } from 'src/app/services/shared/session.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class M3uDetailPageResolver {
  userDetails: any;
  m3uData: any;
  isLoggedIn: any;
  itemDetail: any;
  m3uType: any;
  orderObj: any;
  sharedData: any;
  mediaItemId: any;
  channelName: any;
  constructor(
    private _Meta: Meta,
    private _Title: Title,
    private _HomeService: HomeService,
    private _SessionService: SessionService,
    private _router: Router,
    private _messageService: MessageService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let loginData: any;
    loginData = this._SessionService.getSession('loggedInUser');
    // loginData = localStorage.getItem('loggedInUser')
    this.isLoggedIn = loginData ? true : false;
    this.m3uType = route.queryParams.channelType;
    this.mediaItemId = route.queryParams.mediaItemId;
    // const storedData = localStorage.getItem('m3uData');
    // this.sharedData = JSON.parse(storedData?storedData:'')
    // this.orderObj = JSON.parse(this.sharedData.allData)
    this.channelName = route.queryParams.channelName;
    this.userDetails = this._SessionService.getSession('loggedInUser');
    // if (this.userDetails) {
    return this._HomeService
      .getM3uDetail(this.channelName, this.m3uType)
      .toPromise()
      .then((data: any) => {
        if (data.data) {
          if (this.m3uType === 'M3U_SERIES') {
            if (data.data.seasonData) {
              return data;
            } else {
              this._messageService.add({
                severity: 'Info',
                summary: 'Info',
                detail: 'No details available',
                styleClass: 'infopopup',
              });
              this._router.navigate(['network'], { queryParams: { channelType: this.m3uType } });
            }
          } else {
            return data;
          }
        } else {
          this._messageService.add({
            severity: 'Info',
            summary: 'Info',
            detail: 'No details available',
            styleClass: 'infopopup',
          });
          this._router.navigate(['network'], { queryParams: { channelType: this.m3uType } });
        }
      })
      .catch(function (err: any) {
        //   console.log(err.message); // some coding error in handling happened
      });
    //   }
  }
}
