import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/shared/session.service';

@Injectable()
export class AuthGuard {
  constructor(
    private _SessionService: SessionService,
    private _Router: Router,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this._SessionService.getSession('loggedInUser')) {
      return true;
    }
    this._Router.navigate(['/site-error']);
    return false;
  }
}
