import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFaviconService } from 'angular-favicon';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { HomeService } from 'src/app/services/home/home.service';
import { StripeService, StripeCardComponent, NgxStripeModule } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared/shared.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogModule } from 'primeng/dialog';
import { CarouselModule } from 'primeng/carousel';
import { PaginatorModule } from 'primeng/paginator';
@Component({
  standalone: true,
  imports: [NgbCarouselModule, CommonModule, DialogModule, CarouselModule, FormsModule,NgxStripeModule],
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit {
  appBGColor: any;
  logo: any;
  primaryColor: any;
  favIcon: any;
  stripeTest: any;
  loginUserDetail: any;
  userMail: any;
  cards: any;
  showCard = false;
  cardData: any;
  showaddedCard = false;
  cardBrand: any;
  cardLast4Digit: any;

  userId: any;
  planId: any;

  stripeTokenId: string;
  emailId: any;
  invoiceId: any;
  subDomainName: any;
  subscriptionPlanID: any;
  planName: any;
  changeToRGB: any;
  subscriptionPlanList: any[] = [];
  planSelected: any;
  paymentStatus: boolean;
  showPlan = true;
  displayPlan = false;
  @ViewChild(StripeCardComponent)
  card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    iconStyle: 'solid',
    style: {
      base: {
        'iconColor': '#6d6d6d',
        'color': '#000',
        'fontWeight': '500',
        'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
        'fontSize': '16px',
        '::placeholder': {
          color: '#6d6d6d',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };
  subscribtionDetails: any;
  paymentForm: {
    name: string;
    email: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    country: string;
    zipcode: number | null;
  };
  paymentFormSubmitted: boolean;
  countryList: any[];
  stateList: any[];
  cityList: any[];
  paymentFormSubmitting: boolean;
  isPlanAvailable: boolean;
  constructor(
    public homeService: HomeService,
    private elementRef: ElementRef,
    public messageService: MessageService,
    private ngxFavicon: AngularFaviconService,
    private stripeService: StripeService,
    public router: Router,
    private _SharedService: SharedService,
  ) {
    this.paymentForm = {
      name: '',
      email: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: '',
      zipcode: null,
    };
    this.paymentFormSubmitted = false;
    this.countryList = [];
    this.stateList = [];
    this.cityList = [];
    this.paymentFormSubmitting = false;
    this.isPlanAvailable = false;
  }

  ngOnInit(): void {
    this.loginUserDetail = localStorage.getItem('loggedInUser');
    this.userMail = this.loginUserDetail ? JSON.parse(this.loginUserDetail).userDetails.email : '';
    this.subDomainName = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.organization.subdomain
      : null;
    this.invoiceId = this.loginUserDetail ? JSON.parse(this.loginUserDetail).userDetails.invoiceId : '';
    this.subscriptionPlanID = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.userSubscriptionPlanId
      : '';
    this.paymentStatus = this.loginUserDetail ? JSON.parse(this.loginUserDetail).userDetails.isPaymentDone : false;
    this.subscriptionPlan();
    if (this.subscriptionPlanID) {
      this.getSubscriptionPlanById(this.subscriptionPlanID);
    }
    this.getSecretApiKey();
    // this.pageCustomization();
    this.getSubscribe();
    this.getcardDetails();
    this.getAllCountryList();
  }

  getSecretApiKey() {
    this.homeService.getSecretKey().subscribe((response: any) => {
      if (response.data.stripeApiKey) {
        this.stripeService.setKey(response.data.stripeApiKey);
        setTimeout(() => {
          this.showCard = true;
        }, 1000);
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try after some time or raise a query from contact us page',
        });
      }
      // SubscriptionComponent.apiKey = response.data.stripeApiKey
    });
  }

  getcardDetails() {
    this.homeService.cardDetailsForGiving(false).subscribe((response: any) => {
      const autoPayCard =
        response.data.cardDetails.cards.length &&
        response.data.cardDetails.cards.filter((value: any) => value.autoPayment && value.selectForPayment);

      this.cardData = autoPayCard.length ? autoPayCard[0] : null;
      this.cardBrand = autoPayCard.length ? autoPayCard[0].brand : null;
      this.cardLast4Digit = autoPayCard.length ? autoPayCard[0].last4 : null;
      this.stripeTokenId = autoPayCard.length ? autoPayCard[0].tokenId : null;
      if (this.cardData) {
        this.showaddedCard = true;
      }
      this.paymentForm = {
        name: '',
        email: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        zipcode: null,
      };
      // SubscriptionComponent.apiKey = response.data.stripeApiKey
    });
  }

  replaceCard() {
    this.showaddedCard = false;
    this.isPlanAvailable = true;
  }

  getSubscribe() {
    this.homeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.appBGColor;
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = '';
        // this.logo = response.data.pageCustomizationDTO.logoId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId: ''
        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
        this.addAlpha(this.primaryColor, 0.25);
        this.favIcon = response.data.pageCustomizationDTO.faviconId
          ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.faviconId
          : '';
        this.ngxFavicon.setFavicon(this.favIcon);
      }
    });
  }
  getAllCountryList() {
    this.homeService.getAllCountryList().subscribe(
      (response: any) => {
        this.countryList = response.data.countryList;
        // this.stateList = response.data.countryList[0]
        //   ? response.data.countryList[0].states
        //   : [];
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
        this._SharedService.updateLoader(false);
      },
    );
  }
  updateStateList(event: any) {
    const selectedCountryList = this.countryList.filter((value) => value.code2 === event.target.value);
    this.stateList = [];
    this.paymentForm.state = '';
    if (selectedCountryList.length && selectedCountryList[0].states.length) {
      this.stateList = selectedCountryList[0].states;
    }
  }

  updateCityList(event: any) {
    const selStateObj = this.stateList.filter((el) => el.code === event.target.value);
    if (selStateObj.length && selStateObj[0].cities.length) {
      this.cityList = selStateObj[0].cities;
    } else {
      this.cityList = [];
      this.paymentForm.city = '';
    }
  }
  // pageCustomization() {
  //   this.homeService.pageCustomizationData().subscribe((response: any) => {

  //   this.appBGColor = response.data.pageCustomizationDTO.backgroundColor
  //   this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.appBGColor;
  //     // this.logo = response.data.pageCustomizationDTO.logoId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId: ''
  //   this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
  //   this.addAlpha(this.primaryColor,0.25)
  //   this.favIcon = response.data.pageCustomizationDTO.faviconId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.faviconId : ''
  //   this.ngxFavicon.setFavicon(this.favIcon);
  //   }, (error) => {
  //     let errMsg = error.error.message
  //     if(errMsg.includes('Organization not found with subdomain')){
  //       this.router.navigate(['/site-error'])
  //     }
  //     // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
  //   });
  // }

  rgbaToHex(rgbaColor: any) {
    // Check if the input is a valid RGBA color string
    const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d(?:\.\d+)?))?\)$/;
    const match = rgbaRegex.exec(rgbaColor);

    if (!match) {
      console.error('Invalid RGBA color format.');
      return null;
    }

    // Extract RGBA components
    const red = parseInt(match[1]);
    const green = parseInt(match[2]);
    const blue = parseInt(match[3]);
    const alpha = match[4] ? parseFloat(match[4]) : 1.0;

    // Convert to HEX
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');

    // Alpha is usually not represented in HEX, but you can append it to the end if you want
    // const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0');

    // Concatenate the HEX components (without alpha) and return the result
    return `#${redHex}${greenHex}${blueHex}`;
  }

  addAlpha(color: string, opacity: number): any {
    if (color.includes('#')) {
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = color + _opacity.toString(16).toUpperCase();
    } else {
      // rgba(1,1,22,0.99)
      const data = this.rgbaToHex(color);
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = data + _opacity.toString(16).toUpperCase();
      // this.changeToRGB = color
    }
  }

  getAccess() {
    this.router.navigate(['/signup'], {
      queryParams: { id: this.planId, showOffers: true, clickFrom: 'billing' },
    });
  }

  createToken(): void {
    const name = this.userMail;
    this.paymentFormSubmitted = true;
    if (
      this.paymentForm.name.trim() &&
      this.paymentForm.email.trim() &&
      this.paymentForm.addressLine1.trim() &&
      this.paymentForm.city.trim() &&
      this.paymentForm.country.trim() &&
      this.paymentForm.zipcode &&
      this.paymentForm.state.trim()
    ) {
      this.paymentFormSubmitting = true;
      this.stripeService
        .createToken(this.card.element, {
          name: this.paymentForm.name.trim(),
          address_line1: this.paymentForm.addressLine1.trim(),
          address_line2: this.paymentForm.addressLine2.trim(),
          address_city: this.paymentForm.city.trim(),
          address_country: this.paymentForm.country.trim(),
          address_state: this.paymentForm.state.trim(),
          address_zip: String(this.paymentForm.zipcode),
          currency: 'usd',
        })
        .subscribe(
          (result:any) => {
            this.paymentFormSubmitted = false;
            if (result.token) {
              this.stripeTokenId = result.token.id;
              this.addCardDetails(result);
              this.paymentFormSubmitting = false;
            } else if (result.error) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: result.error
                  ? result.error.message
                  : 'Something went wrong.',
              });
              this.paymentFormSubmitting = false;
            }
          },
          (error:any) => {
            this.paymentFormSubmitting = false;
          }
        );
    }
  }

  addCardDetails(result: any) {
    const data = JSON.parse(JSON.stringify(result));
    const tokenID = data.token.id;
    data['card'] = data.token;
    data['card']['addressCity'] = this.paymentForm.city ? this.paymentForm.city : null;
    data['card']['addressCountry'] = this.paymentForm.country ? this.paymentForm.country : null;
    data['card']['addressLine1'] = this.paymentForm.addressLine1 ? this.paymentForm.addressLine1 : null;
    data['card']['addressLine1Check'] = data.token.card.address_line1_check
      ? data.token.card.address_line1_check
      : null;
    data['card']['addressLine2'] = this.paymentForm.addressLine2 ? this.paymentForm.addressLine2 : null;
    data['card']['addressState'] = this.paymentForm.state ? this.paymentForm.state : null;
    data['card']['addressZip'] = this.paymentForm.zipcode ? this.paymentForm.zipcode : null;
    data['card']['addressZipCheck'] = data.token.card.address_zip_check ? data.token.card.address_zip_check : null;
    data['card']['autoPayment'] = true;
    data['card']['brand'] = data.token.card.brand ? data.token.card.brand : null;
    data['card']['country'] = this.paymentForm.country ? this.paymentForm.country : null;
    data['card']['cvcCheck'] = data.token.card.cvc_check ? data.token.card.cvc_check : null;
    data['card']['dynamicLast4'] = data.token.card.dynamic_last4 ? data.token.card.dynamic_last4 : null;
    data['card']['expMonth'] = data.token.card.exp_month ? data.token.card.exp_month : null;
    data['card']['expYear'] = data.token.card.exp_year ? data.token.card.exp_year : null;
    data['card']['funding'] = data.token.card.funding ? data.token.card.funding : null;
    data['card']['id'] = data.token.card.id ? data.token.card.id : null;
    data['card']['last4'] = data.token.card.last4 ? data.token.card.last4 : null;
    data['card']['name'] = data.token.card.name ? data.token.card.name : null;
    data['card']['object'] = data.token.card.object ? data.token.card.object : null;
    data['card']['selectForPayment'] = true;
    data['card']['tokenId'] = tokenID ? tokenID : null;
    data['card']['tokenizationMethod'] = data.token.card.tokenization_method
      ? data.token.card.tokenization_method
      : null;
    delete data['token'];
    delete data['card']['card'];
    delete data['card']['client_ip'];
    delete data['card']['created'];
    delete data['card']['type'];
    delete data['card']['used'];
    delete data['card']['livemode'];

    this.homeService.addCard(data).subscribe(
      (response: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Card added successfully',
        });
        this.loginUserDetail = localStorage.getItem('loggedInUser');
        this.userId = this.loginUserDetail ? JSON.parse(this.loginUserDetail).userDetails.id : '';
        if (this.loginUserDetail) {
          this.loginUserDetail = localStorage.getItem('loggedInUser');
          this.planId = !this.planId
            ? JSON.parse(this.loginUserDetail).userDetails.userSubscriptionPlanId
            : this.planId;
          if (this.planId) {
            this.subscribePlanforUser(Number(this.planId));
          } else {
            this.getcardDetails();
          }
        }

        this.stateList = [];
        if (this.subscriptionPlanID) {
          this.getSubscriptionPlanById(this.subscriptionPlanID);
        } else {
          this.showaddedCard = true;
          this.isPlanAvailable = false;
        }
      },
      (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error ? error.error.message : 'Something went wrong.',
        });
        this.paymentFormSubmitting = false;
      },
    );
  }

  subscribePlanforUser(planId: any) {
    this.homeService.updateUserSubscription(planId).subscribe((response: any) => {
      if (response.body.data.isIOSUser) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'You are not permitted to change the subscription plan.',
        });
        return;
      }
      this.loginUserDetail = localStorage.getItem('loggedInUser') ? JSON.parse(this.loginUserDetail) : '';
      if (response.body.data.paymentStatus !== 'PAID') {
        this.invoiceId = response.body.data && response.body.data.invoiceId;
        this.makePayment();
      } else {
        this.loginUserDetail.userDetails.isPaymentDone = true;
        this.loginUserDetail.userDetails.invoiceId = response.body.data.invoiceId;
        localStorage.setItem('loggedInUser', JSON.stringify(this.loginUserDetail));
        this.loginUserDetail = localStorage.getItem('loggedInUser');
        this.getcardDetails();
      }
    });
  }

  makePayment() {
    let newdata = {};

    newdata = {
      card: { tokenId: this.stripeTokenId },
      email: this.emailId,
      amount: 0,
      isGiving: false,
      invoiceId: this.invoiceId ? this.invoiceId : null,
      subdomain: this.subDomainName ? this.subDomainName : null,
    };

    this.homeService.makePayment(newdata).subscribe(
      (response: any) => {
        if (response.body.data.paymentStatus === 'Payment Failed') {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: response.body.data.reason,
          });
          this.loginUserDetail = JSON.parse(this.loginUserDetail);
          this.loginUserDetail.userDetails.isPaymentDone = false;
          localStorage.setItem('loggedInUser', JSON.stringify(this.loginUserDetail));
          this._SharedService.updateLoader(false);
        } else if (response.body.data.paymentStatus === 'Payment Succeed') {
          this.loginUserDetail = JSON.parse(this.loginUserDetail);
          this.loginUserDetail.userDetails.isPaymentDone = true;
          localStorage.setItem('loggedInUser', JSON.stringify(this.loginUserDetail));
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Payment Done successfully',
          });
          this.loginUserDetail = localStorage.getItem('loggedInUser');
          this.paymentFormSubmitting = false;
          this.getcardDetails();
          // this.getCardDetailsList();
          // this.showAddCard = false
          this._SharedService.updateLoader(false);
        }
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error ? error.error.message : 'Something went wrong.',
        });
        this.paymentFormSubmitting = false;
      },
    );
  }

  subscriptionPlan() {
    this.homeService.subscriptionPlanList().subscribe(
      (response: any) => {
        this.subscriptionPlanList = response.data;
        this.subscriptionPlanList.forEach((el: any) => {
          if (el.documentId) {
            const width = 320;
            const height = 180;
            const id = el.documentId;
            el['planImg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
          }
          if (el.subscriptionPlanDuration === 'DAILY') {
            el['planDuration'] = 'Daily';
            el['planDurationName'] = 'day';
          } else if (el.subscriptionPlanDuration === 'WEEKLY') {
            el['planDuration'] = 'Weekly';
            el['planDurationName'] = 'week';
          } else if (el.subscriptionPlanDuration === 'MONTHLY') {
            el['planDuration'] = 'Monthly';
            el['planDurationName'] = 'month';
          } else if (el.subscriptionPlanDuration === 'YEARLY') {
            el['planDuration'] = 'Annual';
            el['planDurationName'] = 'year';
          }
        });
        this.subscriptionPlanList.forEach((el) => {
          if (el.id === this.subscriptionPlanID) {
            el.selectedImage = true;
            this.planSelected = true;
            this.planId = el.id;
          }
        });
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      },
    );
  }

  selectPlan(item: any) {
    // this.subscriptionPlanList.forEach((el) => el.selectedImage = false);
    this.subscriptionPlanList.forEach((el, i) => {
      if (el.id === item.id) {
        el.selectedImage = true;
        this.planSelected = true;
        this.planId = el.id;
        this.displayPlan = true;
        // this.selectedPlanData = [];
        // this.selectedPlanData.push(el);
      } else {
        el.selectedImage = false;
      }
    });
  }

  cancelPlan() {
    this.displayPlan = false;
    this.subscriptionPlanList.forEach((el) => {
      el.selectedImage = false;
    });
    this.subscriptionPlanList.forEach((el) => {
      if (el.id === this.subscriptionPlanID) {
        el.selectedImage = true;
        this.planSelected = true;
        this.planId = el.id;
      }
    });
  }

  getSubscriptionPlanById(id: any) {
    this.homeService.subscriptionPlanById(id).subscribe(
      (response: any) => {
        this.planName = response.data.name;
        this.isPlanAvailable = this.planName ? true : false;
        this.subscribtionDetails = response.data;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      },
    );
  }

  changePlan() {
    this.showPlan = false;
    this.userId = this.loginUserDetail ? JSON.parse(this.loginUserDetail).userDetails.id : '';
    this.homeService.updateUserSubscription(this.planId).subscribe(
      (response: any) => {
        if (response.body.data.isIOSUser) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'You are not permitted to change the subscription plan.',
          });
          return;
        }
        this.loginUserDetail = localStorage.getItem('loggedInUser') ? JSON.parse(this.loginUserDetail) : '';
        this.loginUserDetail.userDetails.userSubscriptionPlanId = Number(this.planId);
        this.loginUserDetail.userDetails.invoiceId = response.body.data.invoiceId;
        this.invoiceId = response.body.data.invoiceId;
        if (response.body.data.paymentStatus === 'PAID') {
          this.loginUserDetail.userDetails.isPaymentDone = true;
        }
        localStorage.setItem('loggedInUser', JSON.stringify(this.loginUserDetail));
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Plan change successfully',
        });
        this.showPlan = true;
        this.getSubscriptionPlanById(this.planId);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      },
    );
  }
  zipValidation(event: any) {
    const regex = new RegExp('[0-9]{5}(?:-[0-9]{4})?$');
    this.paymentFormSubmitting = regex.test(event) ? false : true;
  }
  unsubscribePlan() {
    this.router.navigate(['contact-us'], { queryParams: { from: 'billing' } });
  }
}
