import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HomeService } from 'src/app/services/home/home.service';
import { SessionService } from 'src/app/services/shared/session.service';

@Injectable({
  providedIn: 'root',
})
export class HomeResolver {
  isLoggedIn: boolean;
  constructor(
    private _HomeService: HomeService,
    private _SessionService: SessionService,
  ) {
    this.isLoggedIn = false;
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const storedData = this._HomeService.getData();
    if (storedData) {
      // If data is already available, return it
      return of(storedData);
    } else {
      // Implement logic to fetch data if needed
      // Example: return this.http.get('your-api-url/home-data');
      // or any other method to retrieve the data needed for HomeComponent
      return of(/* Data fetched from the API */);
    }
  }
}
