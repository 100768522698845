import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { HomeService } from '../services/home/home.service';

@Injectable()
export class JoinGuard {
  constructor(
    private _HomeService: HomeService,
    private _Router: Router,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this._HomeService
      .pageCustomizationData()
      .toPromise()
      .then((response: any) => {
        if (response.data && response.data.pageCustomizationDTO.isAnySubscriptionPlanExist) {
          return true;
        } else {
          this._Router.navigate(['/signup'], {
            queryParams: { category: 'NOPLAN' },
          });
          return false;
        }
      });
  }
}
