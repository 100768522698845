import { Component } from '@angular/core';
import { ImageDirective } from '../../directives/image.directive';

@Component({
  //  standalone : true,  imports:[NgbCarouselModule,CommonModule,DialogModule,CarouselModule,FormsModule],
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  standalone: true,
  imports: [ImageDirective],
})
export class PaymentPageComponent {}
