import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { HomeService } from '../services/home/home.service';
import { SessionService } from '../services/shared/session.service';

@Injectable()
export class HomeGuard {
  constructor(
    private _SessionService: SessionService,
    private _Router: Router,
    private _HomeService: HomeService,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this._SessionService.getSession('loggedInUser')) {
      this._Router.navigate(['/vod']);
      return false;
    } else {
      return this._HomeService
        .pageCustomizationData()
        .toPromise()
        .then((response: any) => {
          if (response.data && response.data.pageCustomizationDTO.showHideHome) {
            return true;
          } else if (response.data.pageCustomizationDTO.showHideVOD) {
            this._Router.navigate(['/vod'], {
              queryParams: {
                vod: response.data.pageCustomizationDTO.watchDailyPage,
              },
            });
            return false;
          } else if (response.data.pageCustomizationDTO.showHideEbook) {
            this._Router.navigate(['/ebook-list']);
            return false;
          } else if (response.data.pageCustomizationDTO.showHideAppstore) {
            this._Router.navigate(['/mobileApp']);
            return false;
          } else if (response.data.pageCustomizationDTO.showHideGiving) {
            this._Router.navigate(['/giving']);
            return false;
          } else if (response.data.pageCustomizationDTO.isIFrame) {
            this._Router.navigate(['/frame']);
            return false;
          } else if (response.data.pageCustomizationDTO.showHideTvChannel) {
            this._Router.navigate(['/channel']);
            return false;
          } else if (response.data.pageCustomizationDTO.showHideNetwork) {
            this._Router.navigate(['/network']);
            return false;
          } else {
            this._Router.navigate(['/contact-us']);
            return false;
          }
        })
        .catch((error) => true);
    }
  }
}
