import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HomeService } from 'src/app/services/home/home.service';
import { SessionService } from 'src/app/services/shared/session.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProgramsResolver {
  userDetails: any;
  constructor(
    private _Meta: Meta,
    private _Title: Title,
    private _HomeService: HomeService,
    private _SessionService: SessionService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const id = route.params.id;
    this._HomeService.getMediaItems(id).then((response: any) => {
      const ogImage = environment.imageService + response.data.wideArtworkId + '?height=470&width=680';
      this._Title.setTitle(`${response.data.title}`);
      this._Meta.updateTag({
        property: 'og:title',
        content: response.data.title,
      });
      this._Meta.updateTag({ property: 'og:image', content: ogImage });
      this._Meta.updateTag({ property: 'og:url', content: ogImage });
    });
    this.userDetails = this._SessionService.getSession('loggedInUser');
    if (this.userDetails) {
      return this._HomeService
        .getMediaDataByIdWith(id)
        .toPromise()
        .then((data) => data);
    }
    return this._HomeService
      .getMediaDataByIdWithout(id)
      .toPromise()
      .then((response) => response);
  }
}
