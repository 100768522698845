import { Injectable } from '@angular/core';
import { getWindow } from 'ssr-window';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  // setSession(keyValue: Record<string, string>): void {
  //   for (const prop in keyValue) {
  //     if (keyValue.hasOwnProperty(prop)) {
  //       getWindow().localStorage.removeItem(prop);
  //       getWindow().localStorage.setItem(prop, keyValue[prop]);
  //     }
  //   }
  // }
  setSession(keyValue: Record<string, string>): void {
    for (const prop in keyValue) {
      if (Object.prototype.hasOwnProperty.call(keyValue, prop)) {
        getWindow().localStorage.removeItem(prop);
        getWindow().localStorage.setItem(prop, keyValue[prop]);
      }
    }
  }

  getSession(key: string): string | null {
    return getWindow().localStorage.getItem(key);
  }

  removeSessions(key: string): void {
    getWindow().localStorage.removeItem(key);
  }
}
