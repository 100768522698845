import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  loaderState = new Subject();
  loaderState$ = this.loaderState.asObservable();

  constructor() {}

  /**
   * To update loader across the app
   * @param state Show/Hide
   */
  updateLoader(state: any): any {
    this.loaderState.next(state);
  }

  changeToRGB(color: string, opacity: number): any {
    // coerce values so ti is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    // return color + _opacity.toString(16).toUpperCase();
    return color + _opacity.toString(16).toUpperCase();
  }

  // timeConvert(data: any) {
  //   var seconds: any = data;
  //   const hours = Math.floor(seconds / (60 * 60));
  //   seconds -= hours * (60 * 60);
  //   const minutes = Math.floor(seconds / 60);
  //   seconds -= minutes * 60;
  //   seconds = seconds / 100;
  //   if (hours) {
  //     var seconds: any = data;
  //     return new Date(seconds * 1000).toISOString().slice(12, 19);
  //   } else if (minutes) {
  //     var seconds: any = data;
  //     const minLength = new Date(seconds * 1000).toISOString().slice(14, 15);
  //     if (minLength == '0') {
  //       return new Date(seconds * 1000).toISOString().slice(15, 19);
  //     } else {
  //       return new Date(seconds * 1000).toISOString().slice(14, 19);
  //     }
  //   } else {
  //     var seconds: any = data;
  //     return new Date(seconds * 1000).toISOString().slice(16, 19);
  //   }
  // }

  timeConvert(data: any): string {
    const totalSeconds = Number(data);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    if (hours > 0) {
      // Format HH:MM:SS
      return new Date(totalSeconds * 1000).toISOString().slice(11, 19);
    } else if (minutes > 0) {
      // Format MM:SS
      return new Date(totalSeconds * 1000).toISOString().slice(14, 19);
    } else {
      // Format SS
      return new Date(totalSeconds * 1000).toISOString().slice(17, 19);
    }
  }
}
