import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HomeService } from 'src/app/services/home/home.service';
import { SessionService } from 'src/app/services/shared/session.service';

@Injectable({
  providedIn: 'root',
})
export class EbookResolver {
  isLoggedIn: boolean;
  constructor(
    private _HomeService: HomeService,
    private _SessionService: SessionService,
  ) {
    this.isLoggedIn = false;
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    let api1Call;
    let api2Call;
    this.isLoggedIn = this._SessionService.getSession('loggedInUser') ? true : false;
    if (this.isLoggedIn) {
      api1Call = this._HomeService
        .getMediaItemWith(1, 20, '')
        .toPromise()
        .then((data) => data);

      api2Call = this._HomeService
        .getEbookSeriesList('')
        .toPromise()
        .then((data) => data);
    } else {
      api1Call = this._HomeService
        .getMediaItemWithout(1, 20, 'all')
        .toPromise()
        .then((data) => data);

      api2Call = this._HomeService
        .getEbookSeriesListWithout('')
        .toPromise()
        .then((data) => data);
    }

    return Promise.all([api1Call, api2Call])
      .then(([dataFromAPI1, dataFromAPI2]) => ({ dataFromAPI1, dataFromAPI2 }))
      .catch((error) => {
        console.error('Error fetching data:', error);
        throw error;
      });
  }
}
